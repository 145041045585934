import { useFormik } from "formik"
import * as yup from "yup"
import React, { useState } from "react"
import { Space } from "antd"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { Header } from "../../components"
import { TextField, Button, auth, API } from "@project/shared"
import styled, { useTheme } from "styled-components"
import * as Sentry from "@sentry/node"
import { signInWithEmailAndPassword } from "firebase/auth"
import RestrictedRoute from "../../withRestrictedRoute"
import router from "next/router"
import { UserOutlined } from "@ant-design/icons"

declare global {
  interface Window {
    Yappli: any
  }
}

interface LoginType {
  email: string
  password: string
  isEmail?: number
}

const Container = styled.div`
  padding-bottom: 80px;
  background: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 650px) {
    height: 100vh;
  }
`

const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 2px;
  & .text-field {
    width: 500px;
    & label {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 8px;
    }
  }
  .login-error {
    color: #d86464;
    margin-top: 21px;
  }
  .forget-password {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }
  a {
    /* text-decoration: underline; */
    padding-bottom: 8px;
    border-bottom: 1px solid #313131;
  }

  @media screen and (max-width: 650px) {
    .text-field {
      margin: auto;
      width: 70vw;
    }
  }
  @media screen and (max-width: 470px) {
    width: 100%;
    .text-field {
      margin: auto;
      width: 85vw;
    }
  }
`

const TextFieldWrapper = styled.div`
  margin-top: 20px;

  @media (min-width: 610px) {
    .overflow-message-control {
      display: none !important;
    }
  }
`

const StyledButton = styled(Button)`
  margin: auto;
  margin-bottom: 17px;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.BUS};
`

const StyledUserOutlined = styled(UserOutlined)`
  svg {
    font-size: 20px;
  }
  margin-right: 2px;
`

const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
  .ant-input-affix-wrapper {
    padding: 4px 11px;
  }
  .ant-input-prefix {
    margin-right: 8px;
  }
`

const LoginBox = styled(Space)`
  justify-content: center;
  width: 80px;
  height: 80px;
  background: ${(props) => props.theme.background?.secondary};
  border-radius: 50%;
`
const LoginTextWrapper = styled.div`
  font-size: 26px;
  margin: 30px 0 0 0;
  color: ${(props) => props.theme.text.color.light};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-family: ${(props) => props.theme.font.BUPS};
  line-height: 49px;
  @media screen and (max-width: 470px) {
    font-size: 26px;
  }
`
const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [loginError, setLoginError] = useState("")

  const [disablePasswordReset, setDisablePasswordReset] = useState(false)

  /**
   *
   * Accessing global theme
   */

  const theme = useTheme()

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("Please enter phone/email."))
      .when("isEmail", {
        is: "1",
        then: yup.string().email(t("Email address format is incorrect.")),
        otherwise: yup
          .string()
          .nullable()
          .matches(/^\d*$/, t("Phone number can only be number"))
          .matches(
            /^(070|080|090|98|97|96)\d{8}$/,
            t("Phone number is incorrect")
          ),
        // only nepalese and japanese number validation
      }),
    password: yup.string().required(t("Please enter your password")),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
      isEmail: 0,
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const callSendYappliRegist = (uid) => {
    const retry = 3

    const retry_interval = 1000

    const success = function () {
      // 連携成功時の処理を記載してください。
    }
    const mistake = function () {
      // 連携失敗時の処理を記載してください。
    }

    // if (-1 === window.navigator.userAgent.indexOf("Yappli")) {
    //   return
    // }
    let c = 0
    const send = function (o) {
      ++c
      if (c > retry) {
        mistake()
        return
      }
      window &&
        window.Yappli.sendRegisteredId(o, function (a, b) {
          if (a === true) {
            success()
            return
          }
          setTimeout(function () {
            send(o)
          }, retry_interval)
        })
    }
    send(JSON.stringify({ registered_id: uid, token: "notoken" }))
  }
  const handleLogin = async () => {
    setLoading(true)
    try {
      const { data } = await API.post(`/user-login`, {
        email: formik.values.email,
        password: formik.values.password,
      })

      if (!data?.is_verified) {
        router.push(`/signup/verification?id=${data?.id}&phone=${data?.phone}`)
        return
      }

      handleFirebaseLogin(data?.email, formik.values.password)
    } catch (error) {
      if (
        error?.data?.error?.message ===
        "You cannot login at this time, please contact your administrator"
      ) {
        setDisablePasswordReset(true)
      }
      setLoginError(
        t(error?.data?.error?.message) ||
          t("An error has occurred. Please try again later.")
      )
    }
    setLoading(false)
  }

  const handleFirebaseLogin = async (email: string, password: string) => {
    setLoading(true)
    try {
      const data = await signInWithEmailAndPassword(auth, email, password)

      if (!data || !data.user || !auth.currentUser) {
        return
      }
      callSendYappliRegist("i4o541HNqSVLwxaonFTeeXp3sUy1")
      router.push("/top")
    } catch (error) {
      const errorCode = error.code
      if (errorCode === "auth/user-not-found") {
        setLoginError(t("Your email address or password is incorrect"))
      } else if (errorCode === "auth/wrong-password") {
        setLoginError(t("Your email address or password is incorrect"))
      } else if (errorCode === "auth/user-disabled") {
        setLoginError(
          t(
            "We could not login you at this moment. Please contact your administration for inquiry"
          )
        )
      } else {
        setLoginError(t("An error has occurred. Please try again later."))
      }
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  return (
    <>
      <Head>
        <title>{`${t("Login")} | かきおき`}</title>
        <script
          type="text/javascript"
          src="https://yappli.io/v1/sdk.js"
        ></script>
      </Head>
      <Header
        title="アカウントお持ちではない場合、新規登録"
        link="/signup"
        image="/assets/icons/pen.svg"
      />
      <Container>
        <LoginWrapper>
          <LoginBox align="center">
            <img
              src="/assets/icons/login.svg"
              alt="logout"
              height={44}
              width={44}
            />
          </LoginBox>
          <LoginTextWrapper>{t("Login to Mypage")}</LoginTextWrapper>
          {loginError && <div className="login-error">{loginError}</div>}
          <TextFieldWrapper>
            <form onSubmit={formik.handleSubmit}>
              <InputFieldWrapper>
                <TextField
                  name="email"
                  bgcolor="#fff"
                  error={formik.touched.email && formik.errors.email}
                  onChange={(evt) => {
                    if (!isNaN(Number(formik.values.email))) {
                      formik.setFieldValue("isEmail", "0")
                      formik.handleChange(evt)
                    } else {
                      formik.setFieldValue("isEmail", "1")
                      formik.handleChange(evt)
                    }
                    Boolean(loginError) && setLoginError("")
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("Please enter phone/email.")}
                  className="text-field"
                  disableboxshadow
                  height="60px"
                  borderradius="10px"
                  prefix={<StyledUserOutlined className="murda" />}
                />
              </InputFieldWrapper>
              {formik.touched.email && formik?.errors?.email && (
                <br className="overflow-message-control" />
              )}

              <InputFieldWrapper>
                <TextField
                  name="password"
                  bgcolor="#fff"
                  type="password"
                  error={formik.touched.password && formik.errors.password}
                  onChange={(evt) => {
                    formik.handleChange(evt)
                    Boolean(loginError) && setLoginError("")
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("Password")}
                  disableboxshadow
                  className="text-field"
                  height="60px"
                  borderradius="10px"
                  prefix={
                    <img
                      src="/assets/icons/lock_hoso.svg"
                      width={24}
                      height={24}
                    />
                  }
                />
              </InputFieldWrapper>
              <StyledButton
                background={theme?.button.primary}
                width="200px"
                height="60px"
                htmlType="submit"
                loading={loading}
              >
                {t("Login")}
              </StyledButton>
            </form>
          </TextFieldWrapper>
          <a
            className="forget-password"
            href="/password-reset"
            style={{ pointerEvents: !disablePasswordReset ? "auto" : "none" }}
          >
            {t("Forgot password")}
          </a>
        </LoginWrapper>
      </Container>
    </>
  )
}

export default RestrictedRoute(LoginPage)
